<template>
  <v-card class="upload-card fill-height" flat>
    <v-card-actions>
      <v-spacer />
      <v-dialog> </v-dialog>
      <v-btn @click="editEntry()">Neuer Eintrag</v-btn>
    </v-card-actions>

    <v-data-table
      :headers="headers"
      :items="defaultInterestPeriods"
      :loading="loading"
      hide-actions
    >
      <template #items="{ item }">
        <tr>
          <td>{{ item.year }}</td>
          <td>{{ formatDate(item.starts_at) }}</td>
          <td class="text-xs-right">
            <v-btn icon @click="editEntry(item)"><v-icon>edit</v-icon></v-btn>
            <v-btn icon @click="removeEntry(item.id)"
              ><v-icon>delete</v-icon></v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>
    <ModalSingleInterestPeriod
      v-model="dialog"
      :entry="editedEntry"
      @save="saveEntry"
    />
  </v-card>
</template>

<script>
import interestRatesApi from '@/api/interestRatesApi'
import formatDate from 'date-fns/format'
import ModalSingleInterestPeriod from '@/pages/admin/interestRates/ModalSingleInterestPeriod'
import store from '@/store/store'

export default {
  name: 'DefaultInterestPeriods',
  components: { ModalSingleInterestPeriod },
  data() {
    return {
      dialog: false,
      loading: false,
      headers: [
        { text: 'Jahr', value: 'year', sortable: false },
        { text: 'Gültig ab', value: 'starts_at', sortable: false },
        { text: '', value: 'actions', align: 'right', sortable: false },
      ],
      defaultInterestPeriods: [],
      editedEntry: null,
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(new Date(date), 'DD.MM.YYYY')
    },
    async getDefaultInterestPeriods() {
      try {
        this.loading = true
        this.defaultInterestPeriods =
          await interestRatesApi.getDefaultInterestPeriods()
      } catch (e) {
        this.$root.setSnackbar('error', e.message)
      } finally {
        this.loading = false
      }
    },
    async saveEntry(data) {
      const { id, starts_at, year } = data

      try {
        if (id) {
          await interestRatesApi.updateDefaultInterestPeriod(
            id,
            year,
            starts_at
          )
          this.$root.setSnackbar('success', 'Eintrag wurde aktualisiert')
        } else {
          await interestRatesApi.createDefaultInterestPeriod(year, starts_at)
          this.$root.setSnackbar('success', 'Eintrag wurde erstellt')
        }
        await this.getDefaultInterestPeriods()
        await store.dispatch('getDefaultInterestPeriods')
      } catch {
        this.$root.setSnackbar(
          'error',
          'Eintrag konnte nicht gespeichert werden'
        )
      }
    },
    async removeEntry(id) {
      try {
        await interestRatesApi.removeDefaultInterestPeriod(id)
        await this.getDefaultInterestPeriods()
        this.$root.setSnackbar('success', 'Eintrag wurde entfernt')
      } catch {
        this.$root.setSnackbar('error', 'Eintrag konnte nicht entfernt werden')
      }
    },
    editEntry(entry) {
      const currentMonth = formatDate(new Date(), 'MM.YYYY')
      this.editedEntry = entry ?? { valid_from: currentMonth, rate: 0 }
      this.dialog = true
    },
  },
  mounted() {
    this.getDefaultInterestPeriods()
  },
}
</script>

<style scoped></style>

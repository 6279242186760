<template>
  <v-layout row justify-center>
    <v-dialog v-model="isDialog" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <v-dialog
                  ref="dialog"
                  v-model="startsAtDialog"
                  persistent
                  lazy
                  full-width
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formattedDate"
                      label="Gültig ab"
                      append-icon="event"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="dataEntry.starts_at"
                    scrollable
                    @input="pickDate"
                  >
                  </v-date-picker>
                </v-dialog>
                <v-text-field
                  v-model="dataEntry.year"
                  label="Jahr"
                  placeholder="2022"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" flat @click="isDialog = false"
            >Abbrechen</v-btn
          >
          <v-btn color="blue darken-1" primary flat @click="save"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { isDialog } from '@/mixins/isDialog'

export default {
  mixins: [isDialog],
  props: {
    entry: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    startsAtDialog: false,
    dataEntry: {
      starts_at: null,
      year: null,
    },
  }),
  watch: {
    entry: {
      immediate: true,
      handler(value) {
        if (value) {
          this.dataEntry.starts_at = this.parseDate(value.starts_at)
          this.dataEntry.year = value.year
        }
      },
    },
  },
  methods: {
    pickDate(selectedDate) {
      this.dataEntry.starts_at = selectedDate
      this.startsAtDialog = false
    },
    save() {
      const starts_at = this.dataEntry.starts_at
      const year = this.dataEntry.year
      const id = this.entry.id
      this.$emit('save', { starts_at, year, id })
      this.isDialog = false
    },
    parseDate(date) {
      if (!date) return null
      return date
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
  },
  computed: {
    formattedDate() {
      return this.formatDate(this.dataEntry.starts_at)
    },
  },
}
</script>

const isDialog = {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isDialog: {
      get() {
        return Boolean(this.value)
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}

export { isDialog }
